import { NavLink } from "react-router-dom";

// Components
import MenuMobile from "./FooterMobile";

export default function Footer({ img }) {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer className="fixed-bottom">
      <div
        style={{ lineHeight: "100px" }}
        className="row m-0 d-none d-lg-block"
      >
        {/* Section 1 */}
        <div className="col-sm-3 col-12 m-auto py-1 inline-block">
          <h5 className="mb-0">© {year} Animaglia.</h5>
        </div>

        {/* Section 2 */}
        {/* <div className="col-sm-2 col-12 m-auto py-1 text-center inline-block"></div> */}

        {/* Section 3 */}
        <div className="col-sm-9 col-12 m-auto py-1 inline-block">
          <div className="footer-end">
            <div className="px-1 inline-block">
              <NavLink
                to={`/terms`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                Terms
              </NavLink>
            </div>
            <div className="px-1 inline-block">
              <NavLink
                to={`/trading-policy`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                Policy
              </NavLink>
            </div>
            {/* <div className="px-1 inline-block">
              <NavLink
                to={`/provenance`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                Provenance
              </NavLink>
            </div> */}
            <div
              className="px-1 inline-block py-1"
              style={{ paddingLeft: "10px" }}
            >
              <a
                className="link"
                href="https://twitter.com/AnimagliaNFT"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <div className="bubble mb-0">Twitter | X</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="d-lg-none">
        {img && (
          <div style={{ paddingBottom: "90px" }} className="text-center">
            <img src={img} className="yay-footer-img" alt="Sneakpeek" />
          </div>
        )}
        <MenuMobile />
      </div>
    </footer>
  );
}
