// import React from "react";
import {
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons
import Container from "../images/stage3/container.png";

function ETH() {
  return (
    <DynamicContextProvider
      settings={{
        environmentId: "9db4e036-d6d1-406c-85df-59c94eacfb87",
        walletConnectors: [SolanaWalletConnectors],
      }}
    >
      <div className="container-fluid pb-tabbar animaglia-color">
        <Header bgColor={false} />
        <div className="row pt-0 m-0">
          <div className="col-12 p-0">
            <div className="animaglia-center">
              <div>The deadline ended on January 17, 2024.</div>
              <div>
                The measures will be announced later, once the Yaypeg's sale
                takes place.
              </div>
              {/* <img
                src={Container}
                className="d-none d-lg-block animaglia-img-2"
                alt="stage III : Animaglia"
              />
              <img
                src={Container}
                className="d-lg-none container-img"
                alt="stage III : Animaglia"
              />

              <div className="pt-3">
                <DynamicWidget />
              </div> */}

              {/* <div style={{ display: "block" }} className="pt-3">
              <div className="bubble-innactive mb-0">Mint</div>
            </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </DynamicContextProvider>
  );
}

export default ETH;
