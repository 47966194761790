import { useEffect } from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons

function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={false} />

      <div className="row pt-0 m-0 pb-5">
        <div className="col-12 pt-5">
          <div className="text-center pt-5">
            <h1 className="uppercase mb-2">Terms</h1>
            <div className="uppercase pb-3 p">Last Updated: Oct 22, 2023.</div>
            <div className="terms pt-3">
              <p>
                Read these Terms of Use (the “Terms”) carefully because they
                govern your use of the Animaglia’ brand, original collection,
                the website located at &nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia"
                >
                  https://animaglia.com/
                </a>
                &nbsp; and the content and functionalities accessible via the
                website (“Site”) offered by Animaglia (referred to in these
                Terms as either "Animaglia", “us”, “we”,“ours”, “team”, or
                “Company”) Animaglia provides its services (described below) and
                related content to you (“you” or “User”).
              </p>
              <p className="pt-3">
                <strong>1. Definitions:</strong>
              </p>
              <p>
                <strong>(a)</strong>“Animaglia’' refers to the generative art
                piece within each NFT inside of a collection stored in IPFS,
                thus representing a unique Animaglia character.
              </p>
              <p>
                <strong>(b)</strong> "Intellectual Property" or “IP” refers to
                copyrights, patents, trademarks, registered designs, design
                rights, domain names, naming, utility models, know-how and trade
                secrets. Applicable anywhere in the world.
              </p>
              <p>
                <strong>(c)</strong> “Brand License” “Brand” or ”Branding”:
                refers to the logos, marks, branding, naming, and all other
                Intellectual Property use and/or relevant to the Animaglia
                Collection.
              </p>
              <p>
                <strong>(d)</strong> “Holder” refers to the holder of an NFT.
              </p>
              <p>
                <strong>(e)</strong> “Holder License” refers to the intellectual
                property license provided to the Holders.
              </p>
              <p>
                <strong>(f)</strong> “Smart Contract” is a computer program or a
                transaction protocol that is intended to automatically execute,
                control or document legally relevant events and actions
                according to the terms of a contract or an agreement.
              </p>
              <p>
                <strong>(g)</strong> "Wallet" or “Ethereum Wallet” refers to an
                area of applications that let you interact with your Ethereum
                account. Your wallet lets you read your balance, send
                transactions and connect to applications. You need a wallet to
                send funds and manage your ETH, also allowing users to register
                as a unique identifier.
              </p>
              <p>
                <strong>(h)</strong> "Mint" means the creation of a new block in
                the Smart Contract on the Ethereum Mainnet Blockchain. This
                creates a unique identification number that references the
                wallet address from which the request is submitted.
              </p>
              <p>
                <strong>(i)</strong> “Gas Fees” fund the network of computers
                that run the decentralized Ethereum network, meaning that the
                Purchaser will need to pay a Gas Fee for each transaction that
                occurs via the Ethereum network.
              </p>
              <p className="pt-3">
                <strong>2. Agreement to Terms.</strong>
              </p>
              <p>
                <strong>(a)</strong> By using our Site, you agree to be bound by
                these Terms. If you don’t agree to be bound by these Terms, do
                not use the Site.
              </p>
              <p>
                <strong>(b)</strong> By minting a Animaglia NFT you agree to
                these Terms.
              </p>
              <p>
                <strong>(c)</strong> By buying a Animaglia NFT you agree to
                these Terms.
              </p>
              <p>
                <strong>(d)</strong> By agreeing to these Terms, you hereby
                certify that you are at least 18 years of age. If you do not
                agree to these Terms, you must not access or use the Site.
              </p>
              <p className="pt-3">
                <strong>3. Changes to the Terms of Use.</strong> We may update
                these Terms of Use at any time at our discretion. These changes
                will be publicly announced and we will post the changes on this
                page and will indicate at the top of this page the date these
                Terms were last revised. If you continue to use the Site after
                we have posted the updated Terms it means that you accept and
                agree to the changes. If you don’t agree, you may not use the
                Site anymore. We may change or discontinue all or any part of
                the Site, at any time and without notice, at our sole
                discretion.
              </p>
              <p className="pt-3">
                <strong>4. Collection.</strong> Animaglia is a collection
                created by @ccmoret and @yaypegs with the expectation that they
                represent your digital identity through different platforms.
                Each NFT is unique, some rarer than others and live on the
                Ethereum Blockchain using the ERC-721 Standard Token.
              </p>
              <p className="pt-3">
                <strong>5. Gas Fees.</strong> By minting, buying or selling an
                Animaglia on the Website or any other platform, you agree to pay
                all applicable fees, including, but not limited to, any
                transaction fees and Gas Fees, as applicable.
              </p>
              <p className="pt-3">
                <strong>6. Brand License.</strong> The name and Intellectual
                Property involved in the Animaglia NFT Project (“Animaglia”) is
                owned by us. All rights that are not specifically granted to the
                Users in this Terms are reserved by the team. This includes but
                is not limited to the intellectual property rights surrounding
                the images, names, logos, 3D layer files, trademarks, the
                website, the ability to mint 3D or voxel or other versions of
                Animaglia, the look and feel of the user interface, or anything
                else not specifically granted by any of the following licenses.
              </p>
              <p className="pt-3">
                <strong>7. Holder License.</strong> By minting an NFT from the
                Animaglia smart contract or acquiring in secondary sale, you
                will have a global license that is governed by the terms and
                conditions established in the following document&nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/global-license"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia Global License"
                >
                  https://animaglia.com/global-license
                </a>
                &nbsp;
              </p>
              <p className="pt-3">
                <strong>9. Not Intended as Investments.</strong> Animaglia is
                not an investment vehicle. We cannot guarantee or promise that
                this collection of NFTs will be worth more than what you, the
                community and the market want them to be worth. But we promise
                you that we will bring an intangible value to our community
                filled with good vibes. Animaglia is a collectible and
                collectibles are items that can be purchased or sold for much
                more than their original value—the rarer the item, the more it
                will fetch. The market will determine the future value of it.
              </p>
              <p className="pt-3">
                <strong>10. Assumption of Certain Risks.</strong> NFTs are
                intangible digital assets and exist only by virtue of the
                ownership record maintained in the blockchain network. Due to
                the rapidly evolving nature of the digital asset market, it is
                not possible to know all of the risks involved in digital
                assets, and new risks may emerge at any time. The following are
                some, but not all, of the risks associated with NFT:
                <div className="px-5 pt-4">
                  <p>
                    <strong>(a) Blockchain.</strong> Blockchain is a
                    decentralized ledger where smart contracts, digital wallets,
                    and non-fungible tokens live and interact. Animaglia is not
                    responsible for the operation of the blockchain or for any
                    losses due to the blockchain or the use of a digital
                    currency. We are not responsible for (i) internet
                    connectivity, (ii) hardware malfunctions, (iii) software
                    bugs, viruses or exposure to malicious software, (iv)
                    digital wallet security breaches, (v) system failures or
                    outages; or (vi) lost or stolen “private keys.”.
                  </p>
                  <p>
                    <strong>(b) Pricing.</strong> Digital assets price is highly
                    volatile and can be impacted by a variety of factors, both
                    internal (web 3 industry) and/or external (globalized
                    economy). Digital assets also suffer from valuation and
                    liquidity challenges.
                  </p>
                  <p>
                    <strong>(c) Regulation.</strong> The regulatory framework in
                    NFT is changing, being an emerging market means that there
                    could be laws being written as you read this. Changes in the
                    legislative or regulatory environment may impact Animaglia’
                    ability to offer certain products or services. Furthermore,
                    it should be considered that any centralized third-party
                    involved in the process may be affected by regulations.
                  </p>
                  <p>
                    <strong>(d) Taxes.</strong> Tax obligations change depending
                    on the country in which you pay taxes. That is why you are
                    responsible for determining if you must comply with any tax
                    obligation in the country where you reside or pay taxes.
                    Except as required by law, Animaglia is not responsible for
                    determining any Participant's tax reporting or compliance
                    obligations, or other tax obligations, including without
                    limitation payment obligations.
                  </p>
                </div>
              </p>
              <p className="pt-3">
                <strong>11. Restrictions.</strong> You agree that you will not
                permit any third party to do or attempt to do any of the
                following without the Animaglia’ express prior written consent
                in each case: interfere with or disrupt the services or servers
                or networks connected to the services in any manner that could
                negatively affect or inhibit other users from fully enjoying the
                services or that could damage, disable, overburden or impair the
                functioning of the services in any manner; violate any
                applicable local, state, national or international law, or any
                regulations having the force of law, including but not limited
                to the U.S. Department of Treasury's Office of Foreign Assets
                Control (“OFAC”), or which would involve proceeds of any
                unlawful activity.
              </p>
              <p className="pt-3">
                <strong>12. Feedback.</strong> We appreciate feedback, comments,
                ideas, proposals and suggestions for improvements to the Site
                and Animaglia (“Feedback”). If you choose to submit Feedback,
                you agree that we are free to use it without any restriction or
                compensation to you.
              </p>
              <p className="pt-3">
                <strong>13. Contact Information.</strong> If you have any
                questions about these Terms or the Site, contact Animaglia
                at&nbsp;
                <a
                  className="link-text"
                  target="_blank"
                  href="mailto:support@animaglia.com"
                  rel="noopener noreferrer"
                  aria-label="Animaglia"
                >
                  [ support@animaglia.com ]
                </a>
                .
              </p>
              <div className="py-5"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermsPage;
