import { useEffect } from "react";
import { NavLink } from "react-router-dom";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons

function LibraryPage() {
  const d = new Date();
  let year = d.getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={false} />
      <div className="row pt-0 m-0 px-3">
        <div className="col-12 px-0 pt-3">
          {/* Mobile */}
          {/* <div className="d-lg-none pt-3 pb-3">
            <div className="p-grey">
              Explore and discover all the content available in Animaglia
              through the library ••
            </div>
          </div> */}
          {/* <NavLink exact to={`/provenance`} className="">
            <h5 className="text-left mb-0 py-1">
              Provenance <span className="color-gold">-></span>
            </h5>
          </NavLink> */}
          <NavLink exact to={`/eth`} className="">
            <h5 className="text-left mb-0 py-1">
              Add ETH Wallet <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/global-license`} className="">
            <h5 className="text-left mb-0 py-1">
              Global License <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/gaming-rights`} className="">
            <h5 className="text-left mb-0 py-1">
              Gaming Rights <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/terms`} className="">
            <h5 className="text-left mb-0 py-1">
              Terms <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <NavLink exact to={`/trading-policy`} className="">
            <h5 className="text-left mb-0 py-1">
              Trading Policy <span className="color-gold">-></span>
            </h5>
          </NavLink>

          <div className="line my-3"></div>
          <div className="p-grey-subtitle pb-2">Collection</div>

          <NavLink exact to={`/`} className="">
            <h5 className="text-left mb-0 py-1">
              Home - 家 <span className="color-gold">-></span>
            </h5>
          </NavLink>
          <a
            className=""
            href="https://yaypegs.com/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Yaypegs"
          >
            <div className="mb-0">
              Yaypegs - 起源 <span className="color-gold">-></span>
            </div>
          </a>
          <div className="line my-3"></div>

          <div className="p-grey-subtitle pb-2">Twitter | X</div>
          <div className="py-1">
            <a
              className=""
              href="https://twitter.com/AnimagliaNFT"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <div className="mb-0">
                @AnimagliaNFT <span className="color-gold">-></span>
              </div>
            </a>
          </div>
          <div className="py-1">
            <a
              className=""
              href="https://twitter.com/yaypegs"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <div className="mb-0">
                @yaypegs <span className="color-gold">-></span>
              </div>
            </a>
          </div>

          {/* <div className="line my-3"></div>
          <h5 className="mb-0 pb-3">© {year} Animaglia.</h5> */}
        </div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
}

export default LibraryPage;
