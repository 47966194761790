import { useEffect } from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons

function GlobalLicensePage() {
  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={false} />

      <div className="row pt-0 m-0 pb-5">
        <div className="col-12 pt-5">
          <div className="text-center pt-5">
            <h1 className="text-center uppercase mb-2">
              Gaming
              <br />
              Rigths
            </h1>
            <div className="uppercase pb-3 p">Last Updated: Oct 22, 2023.</div>
            <div className="terms pt-3">
              <p>
                Read these Gaming Rigths of Use (“Gaming Rigths”) carefully
                because they govern your use of the Animaglia’ brand and
                original collection and the content and functionalities
                accessible via the website offered by Animaglia (referred to in
                these Gaming Rights License as either "Animaglia",
                "Yaypegs",“us”, “we”,“ours” or “team”) Animaglia provides its
                services (described below) and related content to you (“you” or
                “User”).
              </p>

              <p>
                IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THIS
                AGREEMENT, YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE
                ANY DISPUTE BETWEEN YOU AND YAYPEGS THROUGH BINDING, INDIVIDUAL
                ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY
                SECTION 10 “DISPUTE RESOLUTION” IN &nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/global-license"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia Terms"
                >
                  "GLOBAL LICENSE"
                </a>
                &nbsp;FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU ARE A
                RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS
                ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN SECTION
                10 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 12
                (GOVERNING LAW AND FORUM CHOICE) OR RELEVANT PROVISIONS OF
                APPLICABLE LAW WILL STILL APPLY.
              </p>
              <p className="pt-3">
                <strong>1. Terms & Global License.</strong>
              </p>
              <p>
                The Game Rights License is an extension of the Terms and the
                Global License and operate together. All Restrictions and
                Reservations expressed in the Terms and the Global License have
                power over what will be set forth below
              </p>

              <p className="pt-3">
                <strong>2. Gaming Rights License.</strong>
              </p>
              <p>
                <strong>(a)</strong> Grant. Subject to your acceptance of, and
                compliance with, these Gaming Rights License, unlawfully
                acquiring Your Animaglia NFT and, for so long as you hold Your
                Animaglia NFT (both dates as recorded by the Animaglia Smart
                Contract) (the “Gaming Rights License”), Yaypegs grants to you
                an exclusive, universe-wide, royalty-free, sublicensable license
                to reproduce, distribute, prepare derivative works based upon,
                publicly display, publicly perform, transmit, and otherwise use
                and exploit, Your Animaglia Art (“Gaming Rights License”). The
                License is intended to be broad, enabling you to make both
                commercial and non-commercial uses of Your Animaglia Art and
                Metadata in the gaming realm, in any and all media, whether
                digital or phisical, whether existing now or invented later,
                subject only to the restrictions set forth below.
              </p>

              <p>
                <strong>(b)</strong> Restrictions and Reservations.
              </p>
              <p>
                i. The License extends only to Your Animaglia Art—meaning, the
                complete selection and arrangement of all base layers, features,
                attributes, and other elements that comprise Your Animaglia Art.
                Thus, while the License allows you to create and exploit
                Derivative Animaglia Works, the License does not grant you
                rights in any individual element of Your Animaglia Art, or a
                license to exploit any individual element separate and apart
                from Your Animaglia Art. For example, the License allows you to
                create three-dimensional renditions of, and to add new clothing
                to, Your Animaglia Art, but does not allow you to extract
                individual features (e.g., hair, accessories) for use in a
                separate work.
              </p>
              <p>
                ii. The License does not grant you any rights in or to Yaypegs’
                (or any other) trade names, brands, trade dress, or trademarks
                (e.g., “Animaglia” or “Yaypegs”), all of which are expressly
                reserved to Yaypegs (collectively, “Yaypegs Rights”). You hereby
                agree that any Yayapegs Rights you purport to acquire, together
                with any associated goodwill, shall automatically, immediately,
                and at your expense be assigned to Yaypegs. For the sake of
                clarity, the Yaypegs Rights do not include Your Animaglia Art,
                in which you may acquire trademark rights through the exercise
                of your rights in accordance with, and subject to, these Terms
                and applicable law.
              </p>
              <p>
                iii. Any application to register a trademark in Your Animaglia
                Art must occur during the License Term and be based solely upon
                the actual use of the Animaglia Art in commerce and solely for
                the goods or services in connection with which Your Animaglia
                Art has actually been used in commerce in the applicable
                jurisdiction as of the date of the application. Thus, you may
                not seek to register a trademark in Your Animaglia Art on an
                “intent to use '' basis or where you otherwise have not used
                Your Animaglia Art in commerce.
              </p>
              <p>
                iv. You may not use Your Animaglia Art in a manner that
                expresses hate or encourages violence towards a person or group
                based on membership in a protected class, such as race,
                religion, gender, orientation, or disability.
              </p>
              <p>
                v. You may not use Your Animaglia Art in a manner that violates
                applicable law.
              </p>
              <p>
                vi. All rights not expressly granted herein are reserved by us.
              </p>
              <p>
                <strong>(c)</strong> License Back to Yaypegs. You grant to
                Yaypegs an irrevocable, perpetual, non-exclusive, universe-wide,
                royalty-free, sublicensable license to publicly display and
                otherwise use Your Animaglia Art alongside other Animaglia Art
                for the purpose of promoting or exhibiting the entire Animaglia
                collection.
              </p>

              <p className="pt-3">
                <strong>3. Metadata.</strong>
              </p>
              <p>
                All Animaglia NFT comes with a set of values in a JSON file that
                describe the physical characteristics and abilities of each
                character ("Metadata"). The metadata values cannot be altered in
                the Game, these values are unique and assigned to each character
                randomly and are part of the uniqueness of each character.
              </p>
              <p className="pt-3 pb-5">
                <strong>4. Terms.</strong> This Game Rights License is an
                extension of the Animaglia Terms and Global License. All
                licenses work together. For more information read the&nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia Terms"
                >
                  Terms
                </a>
                &nbsp;&&nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/global-license"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia Terms"
                >
                  Global License
                </a>
                .
              </p>
              <div className="py-5"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GlobalLicensePage;
