import { useEffect } from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons

function GlobalLicensePage() {
  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar">
      <Header bgColor={false} />

      <div className="row pt-0 m-0 pb-5">
        <div className="col-12 pt-5">
          <div className="text-center pt-5">
            <h1 className="text-center uppercase mb-2">
              Global
              <br />
              License
            </h1>
            <div className="uppercase pb-3 p">Last Updated: Oct 22, 2023.</div>
            <div className="terms pt-3">
              <p>
                Animaglia is a collection of unique digital collectible
                characters represented by non-fungible tokens (such tokens,
                “Animaglia NFTs”) minted by a smart contract deployed to the
                Ethereum blockchain at address: which will be announced soon
                (TBA) (the “Animaglia Smart Contract”). The Animaglia Smart
                Contract associates each AnimagliaNFT with a piece of digital
                art displaying one of the Animaglia characters (“Animaglia
                Art").
              </p>
              <p>
                These is an extension of the terms and global license (“Terms"
                or "Golbal License") are a legally binding agreement by and
                between Yaypegs. (“Yaypegs,” “we” or “us”) and any owner of a
                Animaglia NFT (“you” or “Owner”) governing the parties’ rights
                and obligations with respect to Animaglia NFTs and Animaglia
                Art.
              </p>
              <p>NOTICE REGARDING ARBITRATION AND CLASS ACTION WAIVER:</p>
              <p>
                BY ACCEPTING THESE TERMS, YOU ARE AGREEING (WITH LIMITED
                EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND YAYPEGS
                THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT.
                PLEASE REVIEW CAREFULLY SECTION 10 (DISPUTE RESOLUTION) BELOW
                FOR DETAILS REGARDING ARBITRATION. HOWEVER, IF YOU ARE A
                RESIDENT OF A JURISDICTION WHERE APPLICABLE LAW PROHIBITS
                ARBITRATION OF DISPUTES, THE AGREEMENT TO ARBITRATE IN SECTION
                10 WILL NOT APPLY TO YOU, BUT THE PROVISIONS OFSECTION 12
                (GOVERNING LAW AND FORUM CHOICE) AND RELEVANT PROVISIONS
                OFAPPLICABLE LAW WILL STILL APPLY.
              </p>
              <p>
                YOU ALSO WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS ACTION
                LAWSUIT OR CLASS WIDE ARBITRATION.
              </p>
              <p className="pt-3">
                <strong>1. Ownership.</strong>
              </p>
              <p>
                <strong>(a)</strong> You Own Your Animaglia NFT. When you own a
                digital wallet that holds an Animaglia NFT, as recorded by the
                Animaglia Smart Contract, you hold the exclusive right to hold,
                sell, transfer, and execute blockchain transactions involving
                that Animaglia NFT (“Your Animaglia NFT”). Except for the
                Animaglia NFTs we own,Yaypegs has no right or ability to seize,
                freeze, or otherwise modify the ownership of any Animaglia NFT.
              </p>
              <p>
                <strong>(b)</strong> We Own (but License to You) the IP in Your
                Animaglia Art. Yaypegs owns all rights, title, and interest in
                and to the Animaglia Art including any and all
                copyrights,trademarks, and other intellectual property rights
                therein (“IP”). However, we grant you the License (defined
                below) to use the Animaglia Art associated with Your Animaglia
                NFT (“Your Animaglia Art”) for as long as you hold Your
                Animaglia NFT.
              </p>
              <p>
                <strong>(c)</strong> You Own the IP in Your Derivative Animaglia
                Works. As between you and Yaypegs, you own all rights, title and
                interest in and to any “derivative work,” as defined by the
                United States Copyright Act, based upon Your Animaglia Art
                created during the License Term (defined below) (“Derivative
                Animaglia Work”); provided, however, that (i) we retain the
                copyright in the Animaglia Art underlying any Derivative
                Animaglia Work; (ii) your use of any Derivative Animaglia Work
                during and after the License Terms subject to these Terms; and
                (iii) your use of any Derivative Animaglia Work after the
                License Term may require a license from the current owner of the
                Animaglia NFT.
              </p>
              <p>
                <strong>(d)</strong> Utility. Owners may be offered utility,
                benefits, or entitlements (collectively,“Utility”) from time to
                time, but these Terms do not confer any Utility except as
                granted by the License. Yaypegs makes no assurances of any
                Utility. Any Utility may be subject to other terms and
                conditions. Yaypegs will not be responsible in any manner for
                any Utility offered by any third party.
              </p>
              <p className="pt-3">
                <strong>2. License.</strong>
              </p>
              <p>
                <strong>(a)</strong> Grant. Subject to your acceptance of, and
                compliance with, these Terms, unlawfully acquiring Your
                Animaglia NFT and, for so long as you hold Your Animaglia NFT
                (both dates as recorded by the Animaglia Smart Contract) (the
                “License Term”), Yaypegs grants to you an exclusive,
                universe-wide, royalty-free, sublicensable license to reproduce,
                distribute, prepare derivative works based upon, publicly
                display, publicly perform, transmit, and otherwise use and
                exploit, Your Animaglia Art (“License”). The License is intended
                to be broad, enabling you to make both commercial and
                non-commercial uses of Your Animaglia Art, in any and all media,
                whether existing now or invented later, subject only to the
                restrictions set forth below.
              </p>
              <p>
                <strong>(b)</strong> Restrictions and Reservations.
              </p>
              <p>
                i. The License extends only to Your Animaglia Art—meaning, the
                complete selection and arrangement of all base layers, features,
                attributes, and other elements that comprise Your Animaglia Art.
                Thus, while the License allows you to create and exploit
                Derivative Animaglia Works, the License does not grant you
                rights in any individual element of Your Animaglia Art, or a
                license to exploit any individual element separate and apart
                from Your Animaglia Art. For example, the License allows you to
                create three-dimensional renditions of, and to add new clothing
                to, Your Animaglia Art, but does not allow you to extract
                individual features (e.g., hair, accessories) for use in a
                separate work.{" "}
              </p>
              <p>
                ii. The License does not grant you any rights in or to Yaypegs’
                (or any other) trade names, brands, trade dress, or trademarks
                (e.g., “Animaglia” or “Yaypegs”), all of which are expressly
                reserved to Yaypegs (collectively, “Yaypegs Rights”). You hereby
                agree that any Yayapegs Rights you purport to acquire, together
                with any associated goodwill, shall automatically, immediately,
                and at your expense be assigned to Yaypegs. For the sake of
                clarity, the Yaypegs Rights do not include Your Animaglia Art,
                in which you may acquire trademark rights through the exercise
                of your rights in accordance with, and subject to, these Terms
                and applicable law.
              </p>
              <p>
                iii. Any application to register a trademark in Your Animaglia
                Art must occur during the License Term and be based solely upon
                the actual use of the Animaglia Art in commerce and solely for
                the goods or services in connection with which Your Animaglia
                Art has actually been used in commerce in the applicable
                jurisdiction as of the date of the application. Thus, you may
                not seek to register a trademark in Your Animaglia Art on an
                “intent to use '' basis or where you otherwise have not used
                Your Animaglia Art in commerce.
              </p>
              <p>
                iv. You may not use Your Animaglia Art in a manner that
                expresses hate or encourages violence towards a person or group
                based on membership in a protected class, such as race,
                religion, gender, orientation, or disability.
              </p>
              <p>
                v. You may not use Your Animaglia Art in a manner that violates
                applicable law.
              </p>
              <p>
                vi. All rights not expressly granted herein are reserved by us.
              </p>
              <p>
                <strong>(c)</strong> License Back to Yaypegs. You grant to
                Yaypegs an irrevocable, perpetual, non-exclusive, universe-wide,
                royalty-free, sublicensable license to publicly display and
                otherwise use Your Animaglia Art alongside other Animaglia Art
                for the purpose of promoting or exhibiting the entire Animaglia
                collection.
              </p>
              <p className="pt-3">
                <strong>3. Enforcement.</strong>
              </p>

              <p className="pt-3">
                <strong>(a) Copyright Notices.</strong> You may include the
                following copyright notice with Your Animaglia Art: “Animaglia ©
                2023 Yaypegs” (the “Yaypegs Copyright No Uce”). Subject to your
                compliance with these Terms, you may include a copyright notice
                identifying you, or such other person you designate, as the
                copyright owner of any Derivative Animaglia Work created during
                the License Term, provided that you also include the Yaypegs
                Copyright notice.
              </p>
              <p className="pt-3">
                <strong>(b) Copyright Registrations.</strong> Any application to
                obtain a copyright registration in Your Animaglia Art shall
                identify “Yaypegs.” as the copyright owner of the Animaglia Art.
                Any application to obtain a copyright registration in a
                Derivative Animaglia Work may identify you or such other person
                you designate as the copyright owner but shall identify Your
                Animaglia Art as a preexisting work upon which the Derivative
                Animaglia Work is based.
              </p>
              <p className="pt-3">
                <strong>(c) Actions.</strong> To the extent applicable law
                authorizes you to bring a claim for infringement based upon the
                unauthorized use of Your Animaglia Art, you agree that: (i) any
                such claim shall be based solely upon the unauthorized use of
                Your Animaglia Art, not other Animaglia Art—for example, on the
                ground that the other Animaglia Art is substantially similar to
                Your Animaglia Art; and (ii) Yaypegs may, in its sole
                discretion, join and, unless it would materially prejudice your
                rights, elect to take over the control of the prosecution of,
                any such action.
              </p>
              <p className="pt-3">
                <strong>(d) Disputes Among Owners.</strong> Yaypegs has no
                obligation to support the resolution, or resolve any dispute
                that may arise between Animaglia NFT owners.
              </p>

              <p className="pt-3">
                <strong>4. Transfers.</strong>
              </p>
              <p className="pt-3">
                <strong>(a) No Decoupling.</strong> Except as expressly provided
                herein, ownership of an Animaglia NFT and the License are not
                separable in any way. You may not engage in any transaction or
                activity that purports to decouple the License from Your
                Animaglia NFT.
              </p>
              <p className="pt-3">
                <strong>(b) Termination of License.</strong> Upon the transfer
                of Your Animaglia NFT to a new Owner, as recorded by the
                Animaglia Smart Contract: (i) your License hereunder shall
                immediately and automatically terminate; (ii) you must
                discontinue any use of Your Animaglia Art as a trademark or
                other source identifier; and (iii) any trademark and
                corresponding registration obtained in connection with your
                exercise of the License shall be deemed abandoned unless duly
                transferred to the new Owner under a separately negotiated
                written agreement.
              </p>
              <p className="pt-3">
                <strong>(c) Published Animaglia Works.</strong> If, during the
                License Term, you create and make available to the public a work
                using Your Animaglia Art (a “Published Animaglia Work”), you
                may, except as set forth in Section 4(b)(ii), continue to use
                and exploit that Published Animaglia Work in accordance with
                these Terms after the License Term; provided, however, that: (i)
                you will be responsible for any obligations or liabilities
                arising from your continued use of the Published Animaglia Work
                after the LicenseTerm; and (ii) this privilege does not allow
                you to use the Animaglia Art to create any new works or
                materials after the License Term. Thus, for example:
              </p>

              <div className="px-5">
                <p>
                  <strong>-</strong> A digital series featuring Your Animaglia
                  Art that was released during the License Term may continue to
                  run after the License Term, but any creation or distribution
                  of any new episodes featuring the Animaglia Art would require
                  a license from the new Owner.
                </p>
                <p>
                  <strong>-</strong> After the License Term, you may sell off
                  existing (at the time of transfer) inventories of merchandise
                  featuring Your Animaglia Art that were created and offered for
                  sale during the License Term, but the creation or distribution
                  of any new merchandise or inventory featuring the Animaglia
                  Art would require a license from the new Owner.
                </p>
              </div>

              <p className="pt-3">
                <strong>5. Owner’s Representations and Warranties.</strong>
              </p>
              <p>Owner represents and warrants that Owner:</p>

              <p className="pt-3">
                <strong>(a)</strong> is over the age of majority and has the
                legal capacity to enter into these Terms;
              </p>
              <p className="pt-3">
                <strong>(b)</strong> will only use and interact with any
                Animaglia NFT and Animaglia Art in accordance with these Terms;
              </p>
              <p className="pt-3">
                <strong>(c)</strong> will comply with all applicable law in the
                exercise of its rights and obligations under these Terms and
                will not violate any right of Yaypegs, its licensors, or any
                right of any third party; and
              </p>
              <p className="pt-3">
                <strong>(d)</strong> is not, and will not, knowingly execute a
                transaction involving a Animaglia NFT or Animaglia Art with any
                person who is, (i) located in a country that is subject to a
                U.S.Government embargo, or that has been designated by the U.S.
                Government as a terrorist-supporting country; or (ii) listed on
                any U.S. Government list of prohibited or restricted parties.
              </p>

              <p className="pt-3">
                <strong>6. Warranty Disclaimers.</strong>
              </p>

              <p className="pt-3">
                <strong>(a)</strong> EACH ANIMAGLIA NFT AND ANIMAGLIA NFT ART IS
                PROVIDED “AS IS” AND“AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND.
                WITHOUT LIMITING THE FOREGOING, YAYPEGS EXPLICITLY DISCLAIMS ANY
                AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT,
                AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF
                TRADE.
              </p>

              <p className="pt-3">
                <strong>(b)</strong> EACH ANIMAGLIA NFT IS AN INTANGIBLE DIGITAL
                ASSET THAT EXISTS ONLY BY VIRTUE OF THE OWNERSHIP RECORD
                MAINTAINED ON THE ETHEREUM BLOCKCHAIN. ANY TRANSFER OF OWNERSHIP
                THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE
                DECENTRALIZED LEDGER WITHIN THE ETHEREUM BLOCKCHAIN, WHICH
                YAYPEGS DOES NOT CONTROL.
              </p>

              <p className="pt-3">
                <strong>(c)</strong> YAYPEGS WILL NOT BE RESPONSIBLE OR LIABLE
                TO OWNER FOR ANY LOSS INCONNECTION WITH ANY ANIMAGLIA NFT OR
                ANIMAGLIA ART AND TAKES NO RESPONSIBILITY FOR, AND WILL NOT BE
                LIABLE TO OWNER FOR, ANY USE OF OR INABILITY TO USE ANY
                ANIMAGLIA NFT OR ANIMAGLIA ART, INCLUDING BUT NOT LIMITED TO ANY
                LOSSES, DAMAGES, OR CLAIMS ARISING FROM: (I) USER ERROR SUCH AS
                FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR
                MISTYPED WALLET ADDRESSES; (II) THE BEHAVIOR OR OUTPUT OF ANY
                SOFTWARE OR HARDWARE; (III) DATA LOSS OR CORRUPTION; (IV) ANY
                FEATURES, DEVELOPMENT,ERRORS, OR OTHER ISSUES WITH BLOCKCHAIN
                NETWORKS OR WALLETS; (V) UNAUTHORIZED ACCESS TO ANY ANIMAGLIA
                NFT OR ANIMAGLIA ART; OR (VI) THE ACTS OR OMISSIONS OF ANY THIRD
                PARTY.
              </p>

              <p className="pt-3">
                <strong>(d)</strong> SOME JURISDICTIONS DO NOT ALLOW THE
                EXCLUSION OF IMPLIED WARRANTIES IN CONSUMER CONTRACTS, SO THE
                ABOVE EXCLUSION MAY NOT APPLY TO YOU.
              </p>

              <p className="pt-3">
                <strong>7. Assumption of Risk.</strong> Owner accepts and
                acknowledges all risks associated with the following:
              </p>

              <p className="pt-3">
                <strong>(a)</strong> Animaglia NFTs and Animaglia Art may be
                used in myriad ways. While we strongly encourage transparency,
                communication, and research prior to acquiring a Animaglia NFT,
                including to understand what previous and existing uses have
                been made of the Animaglia NFT and Animaglia Art and how those
                uses may affect value,any purchase of a Animaglia NFT is at the
                purchaser’s own risk. Yaypegs is not responsible for verifying
                or providing information on how an Animaglia NFT or its
                Animaglia Art have been exploited. Additional documentation from
                an Owner may be necessary or prudent.
              </p>

              <p className="pt-3">
                <strong>(b)</strong> Yaypegs is not responsible for determining
                or paying any taxes that apply to any Owner’s purchase, sale, or
                transfer of rights in each Animaglia NFT. As between the
                parties, Owner is solely responsible for determining what, if
                any, taxes apply to such transactions.
              </p>

              <p className="pt-3">
                <strong>(c)</strong> Transactions involving Animaglia NFTs and
                Animaglia Art rely on third-party or decentralized platforms,
                systems, or marketplaces. We do not maintain, control, or assume
                any obligations with respect to such platforms, systems, or
                marketplaces. To the extent that Yaypegs provides links or
                access to third party platforms, sites, or other resources, it
                does so only as a convenience and is not responsible for the
                content, products, or services on or available from those third
                parties or through any content displayed thereon.
              </p>

              <p className="pt-3">
                <strong>8. Indemnity.</strong> Owner shall defend, indemnify,
                and hold Yaypegs, its licensors, affiliates, representatives,
                and service providers, and each of them, and all of their
                respective officers, directors, employees, and agents (the
                “Indemnified ParUes”) harmless from and against any and all
                claims, damages, losses, costs, investigations, liabilities,
                judgments, fines, penalties, settlements, interest, expenses,
                and other similar results or occurrences (including attorneys'
                fees) that directly or indirectly arise from, or are related to
                or in connection with, any claim, suit, action, demand, or
                proceeding or other similar occurrence, process, or activity
                that is initiated, made, brought, or financed by a third party
                (including any person who accesses or transacts using any
                Animaglia NFT or Animaglia Art, whether or not such person
                personally purchased a Animaglia NFT) against the Indemnified
                Parties, or on account of the investigation, defense, or
                settlement thereof, arising out of, related to, or in connection
                with: (a) your access to or use of any NFT marketplace or
                third-party services or products; (b) your breach or alleged
                breach of these Terms; (c) your exercise or attempted exercise
                of the License; or (d) your actual or alleged violation of
                applicable law. Counsel to be used in the defense of such claims
                must be approved by Yaypegs in writing prior to retention of
                such counsel and, upon our request, you will allow us to
                participate in the defense of any such claims. You will not
                enter into any settlement or compromise of any claim or
                litigation or that includes an admission of liability without
                our prior written consent.
              </p>

              <p className="pt-3">
                <strong>9. Limitation of Liability.</strong>
              </p>

              <p className="pt-3">
                <strong>(a)</strong> TO THE MAXIMUM EXTENT PERMITTED BY LAW, NO
                INDEMNIFIED PARTY WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
                EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST
                PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY,
                LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE,
                OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES OF ANY
                KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM
                THE USE OF OR INABILITY TO USE OR INTERACT WITH ANY ANIMAGLIA
                NFT OR ANIMAGLIA ART, WHETHER BASED ON WARRANTY, CONTRACT, TORT
                (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL
                THEORY, AND WHETHER OR NOT YAYPEGS OR ITS SERVICE PROVIDERS HAVE
                BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A
                LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
                ESSENTIAL PURPOSE. IN NO EVENT WILL ANY INDEMNIFIED PARTY’S
                CUMULATIVELIABILITY HEREUNDER FROM ALL CAUSES OF ACTION AND ALL
                THEORIES OF LIABILITY EXCEED $100.
              </p>

              <p className="pt-3">
                <strong>(b)</strong> BY PURCHASING OR OWNING A ANIMAGLIA NFT,
                OWNER ACKNOWLEDGES THAT THE EXCLUSIONS AND LIMITATIONS OF
                DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF
                THE BARGAIN BETWEEN YAYPEGS AND OWNER.
              </p>

              <p className="pt-3">
                <strong>10. Dispute Resolution.</strong>
              </p>

              <p className="pt-3">
                <strong>(a)</strong> Mandatory Arbitration of Disputes. Any
                dispute, claim, or controversy arising out of or relating to
                these Terms or the breach, termination, enforcement,
                interpretation, or validity thereof, or the use of the Animaglia
                NFT or Animaglia Art (“Dispute”) must be resolved solely by
                binding, individual arbitration and not in a class,
                representative,or consolidated action or proceeding. Each party
                waives the right to a trial in court and/or by a jury. This
                arbitration provision shall survive any termination of the
                License or these Terms.
              </p>

              <p className="pt-3">
                <strong>(b)</strong> Exceptions. As a limited exception to
                Section 10(a) above: (i) the parties may seek to resolve a
                Dispute in small claims court if it qualifies; and (ii) each
                party retains the right to seek injunctive or other equitable
                relief from a court to prevent (or enjoin) the infringement or
                misappropriation of its intellectual property rights.
              </p>
              <p className="pt-3">
                <strong>(c)</strong> Conducting Arbitration and Arbitration
                Rules. The arbitration will be conducted by the American
                Arbitration Association (“AAA”) under its Consumer Arbitration
                Rules (the “AAA Rules”) then in effect, except as modified by
                these Terms. The AAA Rules are available at www.adr.org or by
                calling 1-800-778-7879. A party who wishes to start arbitration
                must submit a written Demand for Arbitration to AAA and give
                notice to the other party as specified in the AAA Rules. The AAA
                provides a form Demand for Arbitration at www.adr.org. Any
                arbitration hearings will take place in the county (or parish)
                where one lives, with provision to be made for remote
                appearances to the maximum extent permitted by the AAA rules,
                unless the parties both agree to a different location. The
                parties agree that the arbitrator shall have exclusive authority
                to decide all issues relating to the interpretation,
                applicability, enforceability, and scope of these Terms.
              </p>
              <p className="pt-3">
                <strong>(d)</strong> Arbitration Costs. Payment of all filing,
                administration, and arbitrator fees will be governed by the AAA
                Rules, and Yaypegs won’t seek to recover the administration and
                arbitrator fees for which Yaypegs is responsible unless the
                arbitrator finds your Disputeis frivolous. If Yaypegs prevails
                in arbitration, Yaypegs will pay all of its attorneys' fees and
                costs and won’t seek to recover them from you. If you prevail in
                arbitration, you will be entitled to an award of aforneys’ fees
                and expenses to the extent provided under applicable law.
              </p>
              <p className="pt-3">
                <strong>(e)</strong> Injunctive and Declaratory Relief. Except
                as provided in Section 10(d) above, the arbitrator shall
                determine all issues of liability on the merits of any claim
                asserted by either party and may award declaratory or injunctive
                relief only in favor of the individual party seeking relief and
                only to the extent necessary to provide relief warranted by that
                party's individual claim. To the extent that you or Yaypegs
                prevail on a claim and seek public injunctive relief (that is,
                injunctive relief that has the primary purpose and effect of
                prohibiting unlawful acts that threaten future injury to the
                public), the entitlement to and extent of such relief must be
                ligated in a civil court of competent jurisdiction and not in
                arbitration. The parties agree that litigation of any issues of
                public injunctive relief shall be stayed pending the outcome of
                the merits of any individual claims in arbitration.
              </p>
              <p className="pt-3">
                <strong>(f)</strong> Class Action Waiver. YOU AND YAYPEGS AGREE
                THAT EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN ITS
                INDIVIDUAL CAPACITY, AND NOT AS APLAINTIFF OR CLASS MEMBER IN
                ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if a
                Dispute is resolved through arbitration, the arbitrator may not
                consolidate another person’s claims with a party’s claims, and
                may not otherwise preside over any form of a representative or
                class proceeding. If this specific provision is found to be
                unenforceable, then the entirety of this Dispute Resolution
                section shall be null and void.
              </p>
              <p className="pt-3">
                <strong>(g)</strong> Severability. With the exception of any of
                the provisions in Section 10(f) (Class Action Waiver), if an
                arbitrator or court of competent jurisdiction decides that any
                part of these Terms is invalid or unenforceable, the other parts
                of these Terms will still apply.
              </p>

              {/* I'm here */}
              <p className="pt-3">
                <strong>11. Amendments.</strong> YYaypegs reserves the right to
                clarify or amend these Terms by publicly publishing a new
                version of them, including, but not limited to, on &nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia"
                >
                  https://animaglia.com/
                </a>
                &nbsp;, or any successor website.
              </p>
              <p className="pt-3">
                <strong>12. Governing Law and Forum Choice.</strong> These Terms
                and any action related thereto will be governed by the U.S.
                Federal Arbitration Act, federal arbitration law, and the laws
                of the State of New York, without regard to its conflict of laws
                provisions. Except as otherwise expressly set forth in Section
                10 (Dispute Resolution), the exclusive jurisdiction for all
                Disputes (defined above) will be the state and federal courts
                located in the State and City of New York, and you and Yaypegs
                each waive any objection to jurisdiction and venue in such
                courts.
              </p>
              <p className="pt-3">
                <strong>13. Contact Information.</strong>
              </p>
              <p className="pt-3">
                <strong>(a)</strong> The License applies only to the Animaglia
                NFT on the blockchain that Yaypegs, in its sole discretion, may
                designate, which designation shall apply retroactively. Thus,
                for example, if a fork or other event purports to result in
                duplicate Animaglia NFTs, only the non-fungible token recorded
                on the blockchain designated by Yaypegs will be eligible to
                receive the benefit of the License. Any license purportedly
                granted hereunder to the owner of a non-fungible token recorded
                on a blockchain not designated by Yaypegs is void ab initio.
              </p>
              <p className="pt-3">
                <strong>(b)</strong> These Terms will transfer and be binding
                upon and will inure to the benefit of the parties and their
                permitted successors and assigns.
              </p>
              <p className="pt-3">
                <strong>(c)</strong> These Terms constitute the entire
                agreement, and supersede any and all prior or contemporaneous
                representations, understandings and agreements, between the
                parties with respect to the subject matter of these Terms, all
                of which are hereby merged into these Terms. Without limitation,
                the terms of any other document, publication, course of dealing,
                or course of trade will not modify these Terms, except as
                expressly provided in Sections 11 or 13(a) or as the parties may
                agree in writing.
              </p>
              <p className="pt-3">
                <strong>(d)</strong> Failure to promptly enforce a provision of
                these Terms or any rights related to the Animaglia NFT or
                Animaglia Art will not be construed as a waiver of such
                provision or rights.
              </p>
              <p className="pt-3">
                <strong>(e)</strong> Nothing contained in these Terms will be
                deemed to create, or be construed as creating, a joint venture
                or partnership between the parties. Neither party is, by virtue
                of these Terms or otherwise, authorized as an agent or legal
                representative of the other party. Neither party is granted any
                right or authority to assume or to create any obligation or
                responsibility, express or implied, on behalf or in the name of
                the other party. Nothing contained in these Terms will be deemed
                to create any third-party beneficiary right upon any third party
                whatsoever.
              </p>
              <p className="pt-3">
                <strong>(f)</strong> The parties shall execute and deliver to
                the other party any and all such other instruments in reasonable
                mutually acceptable form and substance and shall take any and
                all such other actions as may be reasonably necessary to carry
                the intent of theseTerms into full force and effect.
              </p>
              <p className="pt-3">
                <strong>(g)</strong> If any one or more of the provisions of
                these Terms should be ruled wholly or partly invalid or
                unenforceable, then the provisions held invalid or unenforceable
                will be deemed amended, and the arbitrator, court, or other
                government body is authorized to reform the provision(s) to the
                minimum extent necessary to render them valid and enforceable in
                conformity with the parties’ intent as manifested herein.
              </p>
              <p className="pt-3">
                <strong>(h)</strong> The headings to sections of these Terms are
                for convenience or reference only and do not form a part of
                these Terms and will not in any way affect its interpretation.
              </p>
              <p className="pt-3">
                <strong>(i)</strong> Neither party will be afforded or denied
                preference in the construction of theseTerms, whether by virtue
                of being the drafter or otherwise.
              </p>
              <p className="pt-3">
                <strong>(j)</strong> For purposes of these Terms, the words and
                phrases “include,” “includes,”“including,” and “such as” are
                deemed to be followed by the words “without limitation”.
              </p>
              <p className="pt-3">
                <strong>(k)</strong> Owner may give notice to Yaypegs by
                contacting Yaypegs at &nbsp;
                <a
                  className="link-text"
                  target="_blank"
                  href="mailto:support@yaypegs.com"
                  rel="noopener noreferrer"
                  aria-label="Yaypegs Support"
                >
                  [ support@yaypegs.com ]
                </a>
                . notice is effective upon receipt.
              </p>
              <p className="pt-3">
                <strong>(l)</strong> The parties have agreed to contract
                electronically and, accordingly, electronic signatures or any
                other forms of acceptance permitted by law, will be given the
                same effect and weight as original signatures.
              </p>

              <p className="pt-3">
                <strong>14. Global License Draft.</strong> The license and terms
                presented are based on the terms made by the Yuga Labs team for
                the Cryptopunks license issued in 2022. Modifications were made
                to adapt it to the needs of Yaypegs and Animaglia. These Global
                License, Extra Terms are different from the License and Terms of
                the Yaypegs collection. Each collection must be governed by its
                own license.
              </p>

              <p className="pt-3 pb-5">
                <strong>15. Terms.</strong> This Global License is an extension
                of the Animaglia Terms. Both work together. For more information
                read the&nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia Terms"
                >
                  Terms
                </a>
                .
              </p>
              <div className="py-5"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GlobalLicensePage;
