import { BrowserRouter, Routes, Route } from "react-router-dom";

// General
import Home from "./components/Home";
import ETH from "./components/ETH";
import Library from "./components/Library";
import Provenance from "./components/Provenance";
import Terms from "./components/Terms";
import TradingPolicy from "./components/TradingPolicy";
import GlobalLicense from "./components/GlobalLicense";
import GamingRights from "./components/GamingRights";
import Error from "./components/Error";

function RouteApp() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/eth" element={<ETH />} />
          <Route path="/library" element={<Library />} />
          <Route path="/provenance" element={<Provenance />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/trading-policy" element={<TradingPolicy />} />
          <Route path="/global-license" element={<GlobalLicense />} />
          <Route path="/gaming-rights" element={<GamingRights />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RouteApp;
