import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons
import Error from "../images/stage3/error.png";
import Error_M from "../images/stage3/error-m.png";

function ErrorPage() {
  return (
    <div className="container-fluid pb-tabbar animaglia-color">
      <Header bgColor={false} />
      <div className="row pt-0 m-0">
        <div className="col-12 p-0">
          <div className="animaglia-center">
            <img
              src={Error}
              className="d-none d-lg-block animaglia-img-error"
              alt="Error"
            />
            <img
              src={Error_M}
              className="d-lg-none container-img"
              alt="Error"
            />
            <div style={{ display: "block" }} className="pt-4">
              <NavLink
                to={`/`}
                className={({ isActive }) =>
                  isActive
                    ? "bubble inline-block active"
                    : "bubble inline-block"
                }
              >
                Go Back
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ErrorPage;
