import { useEffect } from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

// Images & Icons

function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0, "instant");
  }, []);

  return (
    <div className="container-fluid pt-header pb-tabbar animaglia-color">
      <Header bgColor={false} />

      <div className="row pt-0 m-0 pb-5">
        <div className="col-12 pt-5">
          <div className="text-center pt-5">
            <h1 className="uppercase mb-2">
              Insider
              <br />
              Trading
              <br />
              Policy
            </h1>
            <div className="uppercase pb-3 p">Last Updated: Oct 22, 2023.</div>
            <div className="terms pt-3">
              <p>
                Thank you for visiting our Insider Trading Policy. Please read
                these Insider Trading Policy (“Trading Policy” or "Policy")
                carefully because they govern your use of the Animaglia’ brand,
                original collection, the website located at &nbsp;
                <a
                  className="link-text"
                  href="https://animaglia.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Animaglia"
                >
                  https://animaglia.com/
                </a>
                &nbsp; and the content and functionalities accessible via the
                website (“Site”) offered by Animaglia (referred to in these
                Terms as either “Animaglia”, “us”, “we”,“ours”, “team”, or
                “Company”) Animaglia provides its services (described below) and
                related content to you (“you” or “User”).
              </p>

              <p className="pt-3">
                <strong>1. Purpose.</strong> Animaglia has adopted this NFT
                Insider Trading Policy (“Policy”) to help ensure that (a)
                Company and any Insiders (defined below) connected to any
                project involving the creation, marketing, distribution, and/or
                sale of non-fungible tokens (“NFTs”) by or on behalf of Company
                (“Project”) comply with applicable laws, (b) any distribution of
                NFTs by Company to Insiders in connection with a Project is
                conducted fairly and on equal footing with distributions to the
                public; and (c) Company, the Project, and Insiders do not have
                even the appearance of improper insider trading.
              </p>

              <p className="pt-3">
                <strong>2. Scope:</strong>
              </p>
              <p>
                <strong>(a)</strong> “Insiders” include all directors, officers,
                and employees of Company, and any other individuals Company may
                designate because they may have access to material nonpublic
                information concerning a Project (“Inside Information”),
                including any artists, developers, project managers,
                contractors, consultants, or other individuals who are providing
                services in connection with Company or the Project, as well as
                all of the employees, representatives, affiliates, family
                members, and others in the households of the aforementioned
                individuals.
              </p>
              <p>
                <strong>(b)</strong> This Policy applies to any and all
                transactions involving NFTs issued by or on behalf of Company,
                including minting NFTs and both primary and secondary sales.
              </p>

              <p className="pt-3">
                <p className="pb-1">
                  <strong>3. Guidance.</strong>
                </p>
                <p>
                  <strong>(a) Generally Prohibited Activity</strong> As a
                  general matter, an Insider may not use or disclose to any
                  third party any Inside Information about Company or a Project
                  to the advantage of the Insider or any other person in
                  connection with the purchase, sale, or other transaction
                  involving an NFT.
                </p>
                <p>
                  <strong>(b) Specific Rules and Prohibitions. </strong> Without
                  in any way limiting the foregoing general prohibition, the
                  following is a non-exhaustive list of specific rules and
                  prohibitions under this Policy:
                </p>

                <div className="px-5">
                  <p>
                    <strong>(1)</strong> NFTs must be allocated to token
                    holders, including Insiders, at random.
                  </p>
                  <p>
                    <strong>(2)</strong> Randomness must be verifiable to the
                    public through the use of NFT provenance hashing or other
                    technology.
                  </p>
                  <p>
                    <strong>(3)</strong> No buying or selling NFTs on the
                    secondary market (including through any peer-to-peer
                    transactions) until the collection is revealed.
                  </p>
                  <p>
                    <strong>(4)</strong> No buying or selling NFTs on the
                    secondary market (including through any peer-to-peer
                    transactions) until seven (7) days after such NFTs are first
                    made available to the public.
                  </p>
                  <p>
                    <strong>(5)</strong> No buying or selling NFTs on the
                    secondary market (including through any peer-to-peer
                    transactions) within five (5) days of any announcement or
                    planned announcement relating to the Project that an Insider
                    knows about in advance and is material.
                  </p>
                  <p>
                    <strong>(6)</strong> No engaging in any activity that may be
                    considered “front-running,” “wash trading,” “pump and dump
                    trading,” “ramping,” “cornering,” or fraudulent, deceptive,
                    or manipulative trading activity, including, without
                    limitation, engaging in any of the following activities for
                    the purpose of creating or inducing a false, misleading, or
                    artificial appearance of activity or value in any NFT:
                  </p>
                  <div className="px-5-m">
                    <p>
                      <strong>•</strong> facilitating the trading of any NFT at
                      successively lower or higher prices;
                    </p>
                    <p>
                      <strong>•</strong> executing or causing the execution of
                      any transaction involving the NFT which causes no material
                      change in the beneficial ownership thereof;
                    </p>
                    <p>
                      <strong>•</strong> participating in, facilitating,
                      assisting, or knowingly transacting with any person or
                      persons for the purpose of artificially, unfairly, or
                      deceptively influencing the market price of an NFT; or
                    </p>
                    <p>
                      <strong>•</strong> otherwise artificially, unduly, or
                      improperly influencing the market price for any NFT in any
                      manner, including without limitation, on or through social
                      media.
                    </p>
                  </div>
                  <p>
                    <strong>(7)</strong> No “tipping” of Inside Information to
                    any person or entity.
                  </p>
                  <p>
                    <strong>(8)</strong> No use of rarity snipers (e.g., Rarity
                    Sniper, Trait Sniper, rarity.tools, icy.tools) or similar
                    services at any time in connection with any Project by or in
                    collaboration with Company.
                  </p>
                </div>
              </p>

              <p className="pt-3">
                <strong>
                  4. Determining whether information is Material and Nonpublic.
                </strong>
              </p>
              <p>
                <strong>(a) Definition of “Material” Information</strong>
              </p>
              <div className="px-5">
                <p>
                  <strong>(1)</strong> There is no bright line test for
                  determining whether particular information is material. Such a
                  determination depends on the facts and circumstances unique to
                  each situation and cannot be made solely based on the
                  potential financial impact of the information.
                </p>
                <p>
                  <strong>(2)</strong> In general, information about Company or
                  a Project should be considered “material” if:
                </p>
                <div className="px-5-m">
                  <p>
                    <strong>•</strong> A reasonable purchaser of NFTs would
                    consider the information significant when deciding whether
                    to buy or sell NFTs; or
                  </p>
                  <p>
                    <strong>•</strong> The information, if disclosed, could be
                    viewed by a reasonable purchaser of NFTs as having
                    significantly altered the total mix of information available
                    in the marketplace about Company or the Project.
                  </p>
                </div>
                <p>
                  <strong>(3)</strong> While it is impossible to identify every
                  type of information that could be deemed “material,” the
                  following matters shall be considered material:
                </p>
                <div className="px-5-m">
                  <p>
                    <strong>•</strong> Rarity tables.
                  </p>
                  <p>
                    <strong>•</strong> The rarity of each individual trait,
                    layer, or element included in the Project.
                  </p>
                  <p>
                    <strong>•</strong> Upcoming airdrops.
                  </p>
                  <p>
                    <strong>•</strong> Upcoming announcements by or on behalf of
                    Company.
                  </p>
                  <p>
                    <strong>•</strong> Upcoming events by, in collaboration
                    with, or on behalf of Company.
                  </p>
                  <p>
                    <strong>•</strong> Potential, future, and ongoing
                    collaborations and partnerships
                  </p>
                  <p>
                    <strong>•</strong> Potential and future utility (including,
                    without limitation, any products, benefits, services,
                    privileges, rights, or opportunities) to be offered to NFT
                    holders.
                  </p>
                  <p>
                    <strong>•</strong> New key members of Company or Project
                    team, including executives, officers, directors, employees,
                    contractors or investors.
                  </p>
                </div>
              </div>
              <p>
                <strong>(b) Definition of “Nonpublic” Information</strong>{" "}
                Information is “nonpublic” if it has not been made known to the
                general market of purchasers or potential purchasers of NFTs
                through a widely circulated news or wire source or social media
                channel operated by Company or authorized by Company to make
                such information public.
              </p>
              <p className="pt-3">
                <strong>5. Remedies for Violation.</strong> Failure to comply
                with this NFT Insider Trading Policy may constitute not only a
                breach of contract with Company, but also may violate applicable
                criminal and civil law. See, e.g., Department of Justice, Former
                Employee Of NFT Marketplace Charged In First Ever Digital Asset
                Insider Trading Scheme, June 1, 2022, at https://bit.ly/3SwXn8m;
                Department of Justice, Three Charged in First Ever
                Cryptocurrency Insider Trading Tipping Scheme, July 21, 2022, at
                https://bit.ly/3A6Scos. In the event that Company determines
                that an Insider violated the NFT Insider Trading Policy, the
                Insider agrees, upon Company’s instruction, to immediately
                disgorge and transfer to Company, or any recipient of Company’s
                choosing, any and all NFTs or profits gained as a result of such
                violation as well as any compensation Insider received from
                Company under their applicable contract. Insider agrees to
                promptly pay and fully satisfy any and all sanctions, fines,
                losses, judgments, or expenses, including, without limitation,
                costs of settlement and attorneys’ fees, incurred or sustained
                by Company as a result of Insider’s failure to comply with this
                Policy. The foregoing remedies are in addition to any other
                remedies, both legal and equitable, available to Company under
                the law.
              </p>
              <p className="pt-3">
                <strong>6. Contact Information.</strong> If you have any
                questions about these Insider Trading Policy, please contact
                Animaglia at&nbsp;
                <a
                  className="link-text"
                  target="_blank"
                  href="mailto:support@animaglia.com"
                  rel="noopener noreferrer"
                  aria-label="Animaglia"
                >
                  [ support@animaglia.com ]
                </a>
                .
              </p>
              <div className="py-5"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TermsPage;
